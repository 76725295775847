import { ISetupModule } from './setup-wizard.types'
import { WelcomeMsg } from './introduction-steps/welcome-msg/welcome-msg'
import { ContactInformation } from './account-configuration-steps/contact-information/contact-information'
import { ReviewDetails } from './final-steps/review-details/review-details'
import { UserInformation } from './account-configuration-steps/user-information/user-information'
import { PlanInformation } from './account-configuration-steps/plan-information/plan-information'
import { ProcessPayment } from './final-steps/process-payment/process-payment'
import { ConfirmEmail as ConfirmEmailComponent } from './account-configuration-steps/confirm-email/confirm-email'
import { SignupComplete as SignupCompleteComponent } from './final-steps/signup-complete/signup-complete'
import { VerifyEmail as VerifyEmailComponent } from './account-configuration-steps/verify-email/verify-email'

export enum SetupModules {
  Introduction = 'Introduction',
  AccountConfiguration = 'Account Configuration',
  Final = 'Final Steps',
}

export enum SetupModulesParams {
  Introduction = 'introduction',
  AccountConfiguration = 'account',
  Final = 'final',
}

export enum AccountConfigSteps {
  UserInfo = 'user',
  ConfirmEmail = 'confirmEmail',
  ContactInfo = 'contact',
  BillingInfo = 'billing',
  PlanInfo = 'plan',
  VerifyEmail = 'verifyEmail'
}

export enum AccountConfigStepParams {
  UserInfo = 'userInfo',
  ConfirmEmail = 'confirmEmail',
  ContactInfo = 'contactInfo',
  BillingInfo = 'billingInfo',
  PlanInfo = 'planInfo',
  VerifyEmail = 'verifyEmail'
}

export enum IntroConfigSteps {
  Welcome = 'welcome',
}

export enum IntroConfigStepParams {
  Welcome = 'welcome',
}

export enum FinalConfigSteps {
  Review = 'review',
  Payment = 'processPayment',
  SignupComplete = 'signupComplete',
}

export enum FinalConfigStepParams {
  Review = 'review',
  Payment = 'processPayment',
  SignupComplete = 'signupComplete',
}

// TODO: change code so it does not refer to id number as id numbers should be in sequence

export const setupModuleList: ISetupModule[] = [
  {
    id: 1,
    name: SetupModules.Introduction,
    param: SetupModulesParams.Introduction,
    steps: [
      {
        id: 1,
        name: IntroConfigSteps.Welcome,
        label: 'Welcome',
        component: WelcomeMsg,
        param: IntroConfigStepParams.Welcome,
      },
    ],
  },
  {
    id: 2,
    name: SetupModules.AccountConfiguration,
    param: SetupModulesParams.AccountConfiguration,
    steps: [
      {
        id: 1,
        name: AccountConfigSteps.UserInfo,
        label: 'User Information',
        component: UserInformation,
        param: AccountConfigStepParams.UserInfo,
      },
      {
        id: 2,
        name: AccountConfigSteps.ConfirmEmail,
        label: 'Confirm Email',
        component: ConfirmEmailComponent,
        param: AccountConfigStepParams.ConfirmEmail,
      },
      {
        id: 3,
        name: AccountConfigSteps.VerifyEmail,
        label: 'Verify Email',
        component: VerifyEmailComponent,
        param: AccountConfigStepParams.VerifyEmail,
      },
      {
        id: 4,
        name: AccountConfigSteps.ContactInfo,
        label: 'Company Information',
        component: ContactInformation,
        param: AccountConfigStepParams.ContactInfo,
      },
      {
        id: 5,
        name: AccountConfigSteps.PlanInfo,
        label: 'Plan Information',
        component: PlanInformation,
        param: AccountConfigStepParams.PlanInfo,
      },
    ],
  },
  {
    id: 3,
    name: SetupModules.Final,
    param: SetupModulesParams.Final,
    steps: [
      {
        id: 1,
        name: FinalConfigSteps.Review,
        label: 'Review Details',
        component: ReviewDetails,
        param: FinalConfigStepParams.Review,
      },
      {
        id: 2,
        name: FinalConfigSteps.Payment,
        label: 'Process Payment',
        component: ProcessPayment,
        param: FinalConfigStepParams.Payment,
      },
      {
        id: 3,
        name: FinalConfigSteps.SignupComplete,
        label: 'Signup Complete',
        component: SignupCompleteComponent,
        param: FinalConfigStepParams.SignupComplete,
      },
    ],
  },
]

export enum SetupWizardAction {
  Back = 'Go Back',
  Skip = 'Skip',
  Cancel = 'Cancel',
  Next = 'Next',
  Save = 'Save',
}

export enum ICloudParams{
  ContactNumber = 'contact_number',
  FirstName = 'first_name',
  Middlename = 'middle_name',
  LastName = 'last_name',
  Suffix = 'suffix',
  Email = 'email',
  ContactAddress = 'contact_address',
  Company = 'company',
  Phones = 'phones',
  HostName = 'host_name'
}

export enum IAddressParams{
  AddressNumber = 'address_number',
  Account = 'account',
  Address1 = 'address1',
  Address2 = 'address2',
  Address3 = 'address3',
  City = 'city',
  State = 'state',
  Country='country',
  PostalCode = 'postalCode'
}

export enum IPhoneParams{
  ID = 'id',
  Number = 'number',
  AlloSMS ='allowsms',
  Role = 'role'
}

export enum IResponseCodes {
  Success = 'success'
}

export const Primary = 'Primary'