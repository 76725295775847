import { ISetupWizardDialogState, ISetupWizardModuleState } from './setup-wizard.types'
import { setupModuleList, SetupModulesParams } from './setup-wizard.exports'

import { defaultContactInfoState } from './account-configuration-steps/contact-information/contact-information.constants'
import { defaultUserInfoState } from './account-configuration-steps/user-information/user-information.constants'
import { defaultPlanInfoState } from './account-configuration-steps/plan-information/plan-information.constants'
import { defaultPaymentState } from './final-steps/process-payment/process-payment.constants'

export const defaultSetupWizardRoute: string = '/account/welcome'
export const setupWizardRoutePath: string = '/:activeModule/:activeStep'
export const setupWizardServiceToken = 'SetupWizardService'

export const defaultWizardDialogState: ISetupWizardDialogState = {
  activeModule: setupModuleList[0],
  activeStep: {
    id: 1,
    state: setupModuleList[0].steps[0],
  },
  isDirty: false,
  isValid: false,
  isLastStep: false,
  validate: () => {}
}

export const defaultWizardState: ISetupWizardModuleState = {
  [SetupModulesParams.Introduction]: {
    welcomeMsg: null,
  },
  [SetupModulesParams.AccountConfiguration]: {
    userInfo: defaultUserInfoState,
    contactInfo: defaultContactInfoState,
    planInfo: defaultPlanInfoState
  },
  [SetupModulesParams.Final]: {
    processPayment: defaultPaymentState,
    reviewDetails: null
  },
}
