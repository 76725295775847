import { replaceAll } from 'common/utils/utils'
import { validationRegExp } from 'common/validation/validation'
import { ISetupWizardModuleState } from 'setup-wizard/setup-wizard.types'
import { IAccountInfoFormState } from '../contact-information/contact-information.types'
import { defaultPlanInfoState } from './plan-information.constants'
import { IPlanInfoFormState } from './plan-information.types'

function trimSpecialCharsAtLeft(s : string) : string{
  for(let char of s){
    if(validationRegExp.alphanumeric.test(char)){
      break
    }
    s = s.replace(char,'')
  }
return s
}

function trimSpecialChars(s : string) : string{
  let trimmedStr =  trimSpecialCharsAtLeft(s)
  const reverseTrimmedStr = trimSpecialCharsAtLeft(trimmedStr.split('').reverse().join(''))
  return reverseTrimmedStr.split('').reverse().join('')
}

export function formatHostname(s : string) : string{
  let hostName = replaceAll(s,validationRegExp.hostName,'')  
  return trimSpecialChars(hostName)

}

function generateHostname({hostname = ''} : IPlanInfoFormState, {companyName = ''} : IAccountInfoFormState) : string {
  if(!hostname){
    hostname = companyName ? formatHostname(companyName.toLowerCase()) : defaultPlanInfoState.hostname
  }
  return hostname
}

export function generateDefaultData(state: ISetupWizardModuleState): IPlanInfoFormState {

  let hostname : string = state?.account?.planInfo?.hostname
  if(!hostname){
    hostname = state?.account?.contactInfo?.companyName ? formatHostname(state?.account?.contactInfo?.companyName?.toLowerCase()) : ''
  }
  
  const data = {
    hostname : generateHostname(state?.account?.planInfo,state?.account.contactInfo),
    users : state?.account?.planInfo?.users ? state?.account?.planInfo?.users : defaultPlanInfoState.users,
    billing : state?.account?.planInfo.billing ? state?.account?.planInfo.billing : defaultPlanInfoState.billing 
  }

  return data
}

export function calculateCost(users: number, billing: string): number {
  return billing === 'M' ? users * 50 : users * 500
}
