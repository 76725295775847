import * as Yup from 'yup'
import { validationMessage } from 'common/validation/validation'
import { IAccountInfoFormField } from './contact-information.constants'

export const ContactFormSchema = Yup.object().shape({
  [IAccountInfoFormField.CompanyName]: Yup.string()
    .required(validationMessage.isRequired)
    .typeError(validationMessage.isRequired),
  [IAccountInfoFormField.Address]: Yup.string()
    .required(validationMessage.isRequired)
    .typeError(validationMessage.isRequired),
  [IAccountInfoFormField.Country]: Yup.string()
    .required(validationMessage.isRequired)
    .typeError(validationMessage.isRequired),
  [IAccountInfoFormField.City]: Yup.string()
    .required(validationMessage.isRequired)
    .typeError(validationMessage.isRequired),
  [IAccountInfoFormField.State]: Yup.string()
    .required(validationMessage.isRequired)
    .typeError(validationMessage.isRequired),
  [IAccountInfoFormField.ZipCode]: Yup.string()
    .required(validationMessage.isRequired)
    .typeError(validationMessage.isRequired),
})