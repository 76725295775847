import * as Yup from 'yup'
import { validationMessage, validationRegExp } from 'common/validation/validation'
import { IUserInfoFormField } from './user-information.constants'

export const UserFormSchema = Yup.object().shape({
  [IUserInfoFormField.Username]: Yup.string()
    .required(validationMessage.isRequired)
    .matches(validationRegExp.email, {
      message: validationMessage.invalidEmail,
    }),
  [IUserInfoFormField.FirstName]: Yup.string()
    .required(validationMessage.isRequired)
    .typeError(validationMessage.isRequired),
  [IUserInfoFormField.LastName]: Yup.string()
    .required(validationMessage.isRequired)
    .typeError(validationMessage.isRequired),
  [IUserInfoFormField.Phone]: Yup.string()
    .required(validationMessage.isRequired)
    .typeError(validationMessage.isRequired)
    .matches(validationRegExp.phone, {
      message: validationMessage.invalidPhone,
    }),
  [IUserInfoFormField.Password]: Yup.string()
    .required(validationMessage.isRequired)
    .typeError(validationMessage.isRequired),
  [IUserInfoFormField.VerifyPassword]: Yup.string()
    .required(validationMessage.isRequired)
    .oneOf([Yup.ref(IUserInfoFormField.Password)], validationMessage.passwordMatch)
})