import React, { FC, useEffect, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ErrorHandler } from 'services/error-handler.service'
import { FormField, FormXtAutocomplete } from 'common/utils/form/form.components'
import { DictionaryUtilsService } from 'dictionary/dictionary-utils.service'
import { CountryOption } from 'dictionary/dictionary.types'
import { DictionaryService } from 'dictionary/dictionary.service'
import { IAccountInfoFormField, IAccountInfoFormLabel, defaultContactInfoState } from './contact-information.constants'
import { IAccountInfoFormState, IContactInformation } from './contact-information.types'
import { ContactFormSchema } from './contact-information.validation'
import { defineContactInfoData } from './contact-information.utils'
import styles from './contact-information.module.scss'
import { Typography } from '@material-ui/core'
import { XtInfoMessages, XtTypographyVariants } from 'common/constants'

export const ContactInformation: FC<IContactInformation> = ({ state, onChange }) => {
  const {
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { isDirty, isValid },
  } = useForm<IAccountInfoFormState>({
    defaultValues: defaultContactInfoState,
    mode: 'onChange',
    resolver: yupResolver(ContactFormSchema),
  })
  const [countrySelected] = watch([IAccountInfoFormField.Country])
  const country = countrySelected as CountryOption

  const handleCountryChange = useCallback(
    async (option: CountryOption | null) => {
      if (!option?.id) {
        setValue(IAccountInfoFormField.Country, null, {
          shouldValidate: true,
          shouldDirty: true,
        })
        setValue(IAccountInfoFormField.State, '', { shouldValidate: true, shouldDirty: true })
        return
      }
      const statesData = await DictionaryService.getStates(option.id, { limit: 1, page: 1 })
      const hasStates = Boolean(statesData?.data?.length)
      setValue(IAccountInfoFormField.Country, option ? { ...option, hasStates } : null, {
        shouldValidate: true,
        shouldDirty: true,
      })

      if (hasStates) {
        setValue(IAccountInfoFormField.State, null, { shouldValidate: true, shouldDirty: true })
      }
      if (country?.hasStates && !hasStates) {
        setValue(IAccountInfoFormField.State, '', { shouldValidate: true, shouldDirty: true })
      }
    },
    [country?.hasStates, setValue]
  )

  const init: () => Promise<void> = async () => {
    try {
      const countryStates = await DictionaryUtilsService.getCountryStates(
        state?.account.contactInfo.country?.id,
        typeof state?.account?.contactInfo?.state == 'string' ? state?.account?.contactInfo?.state : state?.account?.contactInfo?.state?.id
      )
      reset(defineContactInfoData(state.account.contactInfo, countryStates))
    } catch (e) {
      ErrorHandler.handleError(e as Error)
    }
  }

  useEffect(() => {
    const subscription = watch((value) => {
      onChange(value, isDirty, isValid)
    })
    return () => subscription.unsubscribe()
    // eslint-disable-next-line
  }, [watch, isDirty, isValid])

  useEffect(() => {
    const values = getValues()
    onChange(values, isDirty, isValid)
    // eslint-disable-next-line
  }, [isDirty, isValid, getValues])

  useEffect(() => {
    if (state.account.contactInfo !== defaultContactInfoState) {
      void init()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    onChange(state.account.contactInfo, isDirty, isValid)
    // eslint-disable-next-line
  }, [isDirty, isValid])

  return (
    <div>
      <p>Thank you for confirming your email address. Next, tell us about your company:</p>
      <form>
        <FormField
          control={control}
          name={IAccountInfoFormField.CompanyName}
          label={`${IAccountInfoFormLabel.CompanyName}*`}
          className={styles.companyField}
        />
        <div className={styles.contactInfoAddress}>
          <FormField
            control={control}
            name={IAccountInfoFormField.Address}
            label={`${IAccountInfoFormLabel.Address}*`}
            className={styles.contactInfoBillingAddress1}
          />
          <div className={styles.additionalAddress}>
            <FormField
              control={control}
              name={IAccountInfoFormField.Address2}
              label={IAccountInfoFormLabel.Address2}
              className={styles.contactInfoBillingAddress2}
            />
            <FormField
              control={control}
              name={IAccountInfoFormField.Address3}
              label={IAccountInfoFormLabel.Address3}
              className={styles.contactInfoBillingAddress3}
            />
          </div>
          <div className={styles.cityStateZip}>
            <FormXtAutocomplete
              label={`${IAccountInfoFormLabel.Country}*`}
              control={control}
              name={IAccountInfoFormField.Country}
              loadOptions={DictionaryUtilsService.loadCountries}
              onChange={handleCountryChange}
              className={styles.countryField}
            />
            {!country?.hasStates ? (
              <FormField
                control={control}
                name={IAccountInfoFormField.State}
                label={`${IAccountInfoFormLabel.State}*`}
                className={styles.stateField}
              />
            ) : (
              <FormXtAutocomplete
                label={`${IAccountInfoFormLabel.State}*`}
                control={control}
                name={IAccountInfoFormField.State}
                loadOptions={DictionaryUtilsService.loadStates}
                filters={country?.id ?? null}
                className={styles.stateField}
              />
            )}
            <FormField
              control={control}
              name={IAccountInfoFormField.City}
              label={`${IAccountInfoFormLabel.City}*`}
              className={styles.cityField}
            />
            <FormField
              control={control}
              name={IAccountInfoFormField.ZipCode}
              label={`${IAccountInfoFormLabel.ZipCode}*`}
              className={styles.zipCodeField}
            />
          </div>
        </div>
      </form>
      <Typography variant={XtTypographyVariants.Caption}>
        {XtInfoMessages.RequiredFields}
      </Typography>
    </div>
  )
}
