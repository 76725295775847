import React, { FC, useEffect } from 'react'
import {  useLocation } from 'react-router'
import { parseQueryString } from 'common/utils/utils'
import { IInvoice } from './signup-complete.types'
import { genInvoiceReqObj } from './signup-complete.utils'
import { SetupWizardService } from 'setup-wizard/setup-wizard.service'
import { ErrorHandler } from 'services/error-handler.service'
import { ToastService } from 'services/toast/toast.service'


export const SignupComplete: FC = () => {
  const {search} = useLocation()
  const invoiceReq : IInvoice  = genInvoiceReqObj(parseQueryString(search))

  useEffect(()=>{
      if(invoiceReq.customer_number && invoiceReq.payment_amount > 0)
      {
        SetupWizardService.createInvoice(invoiceReq).catch((e)  => console.error(ErrorHandler.handleError(e as Error)))
      }
    else
      {
        ToastService.showError('something went wrong')
      }
  },[])
  

  return (
    <div>
      <p>
      {`Thank you!  We have processed your payment, and are currently setting up your xT Sales database.  You should receive an email shortly (to ${invoiceReq?.email ? invoiceReq.email : 'your email'}) with instructions on how to access your system.  If you do not receive that email within a few minutes, please contact us at xthelp@caisoft.com.`}
      </p>
    </div>
  )
}
