import * as React from 'react'
import { FC } from 'react'

import { cls } from '../../common/utils/utils'
import { ISvgIconProps } from './svg-icon.types'
import styles from './svg-icon.module.scss'

export const SvgIcon: FC<ISvgIconProps> = ({ iconId, className, hidden = false }) => (
  <svg className={cls(styles.svgIcon, className, hidden ? styles.svgIconHidden : '')}>
    <use xlinkHref={`/images/icons/icons.svg#${iconId}`} />
  </svg>
)
