import * as Yup from 'yup'
import { validationMessage, validationRegExp } from 'common/validation/validation'
import { IResendEmailFormField } from './resend-email.constants'

export const ResendEmailFormSchema = Yup.object().shape({
    [IResendEmailFormField.Email]: Yup.string()
      .required(validationMessage.isRequired)
      .matches(validationRegExp.email, {
        message: validationMessage.invalidEmail,
      })
  })
