import { CountryStates } from 'dictionary/dictionary.types'
import { IAccountInfoFormState } from './contact-information.types'
import { IAccountInfoFormField } from './contact-information.constants'

export function defineContactInfoData(data: IAccountInfoFormState, countryStates: CountryStates): IAccountInfoFormState {
  return {
    [IAccountInfoFormField.CompanyName]: data.companyName ?? '',
    [IAccountInfoFormField.Address]: data.address ?? '',
    [IAccountInfoFormField.Address2]: data.address2 ?? '',
    [IAccountInfoFormField.Address3]: data.address3 ?? '',
    [IAccountInfoFormField.City]: data.city ?? '',
    [IAccountInfoFormField.ZipCode]: data.zipCode ?? '',
    [IAccountInfoFormField.Country]: countryStates?.initialCountry ?? null,
    [IAccountInfoFormField.State]: countryStates?.initialState ?? '',
  }
}
