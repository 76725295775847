import React,{FC} from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormFieldTypes } from 'common/hooks/form/form.exports'
import { SetupWizardService } from 'setup-wizard/setup-wizard.service'
import { FormField } from 'common/utils/form/form.components'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { XtButtonLabels } from 'components/buttons/xt-button/xt-button.export'
import { ErrorHandler } from 'services/error-handler.service'
import { defaultResendEmailState, IResendEmailFormField, IResendEmailFormLabel } from './resend-email.constants'
import {  IResendEmailFormState } from './resend-email.types'
import { ResendEmailFormSchema } from './resend-email.validation'
import  styles from './resend-email.module.scss'
import { ToastService } from 'services/toast/toast.service'
import { IResponseCodes } from 'setup-wizard/setup-wizard.exports'
export const ResendEmail : FC<{}> = () =>{ 
    const {
        control,
        getValues,
        reset,
        formState: {  isValid },
      } = useForm<IResendEmailFormState>({
        defaultValues: defaultResendEmailState,
        mode: 'onChange',
        resolver: yupResolver(ResendEmailFormSchema),
      })


    const requestResend = async(e : React.FormEvent) : Promise<void> => {
        try{
            e.preventDefault()
            const {email} : {email : string}= getValues()
            const code : string = await SetupWizardService.resendEmail(email)
            
            if(code === IResponseCodes.Success){
              ToastService.showSuccess(`Verification email has been sent to your email`)
              reset(defaultResendEmailState)
            }
        }
        catch(error){
            ErrorHandler.handleError(error as Error)
        }
    }

    return (
      <>
        <form onSubmit={requestResend} className={styles.container}>
        <FormField
          control={control}
          name={IResendEmailFormField.Email}
          label={IResendEmailFormLabel.Email}
          type={FormFieldTypes.Email}
        />
        <XtButton type="submit" label={XtButtonLabels.Submit} disabled={!isValid}/>
        </form>
        <p className={styles.instructions}>Please enter the Email id you used during sign up. We will resend the verification email.</p>
      </>
    )
}