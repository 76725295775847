import React, { FC, useState, useEffect } from 'react'
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { SetupWizardService } from 'setup-wizard/setup-wizard.service'
import { CheckoutForm } from './checkout-form/checkout-form'
import { IProcessPayment } from './process-payment.types'

const stripePromise = loadStripe("pk_test_moOhjcwf5BUOTkHrclHKjBSV")

export const ProcessPayment: FC<IProcessPayment> = ({ state, onChange }) => {
  const [clientSecret, setClientSecret] = useState('')
  const [paymentQuote, setQuote] = useState(0)
  const [billingCycle, setCycle] = useState('')

  const options:StripeElementsOptions = {
    clientSecret,
    appearance: {
      theme: "stripe"
    }
  }

  const fetchSecret = async (): Promise<void> => {
    try {
      const resp = await SetupWizardService.getSecret(state.account.planInfo.users, state.account.planInfo.billing)
      setClientSecret(resp.client_secret)
      setQuote(resp?.amount > 0 ? resp?.amount : 0)
      setCycle(state.account.planInfo.billing === 'M' ? 'monthly' : 'yearly')
    }
    catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    void fetchSecret()
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm quote={paymentQuote} billing={billingCycle} onChange={onChange} state={state} />
        </Elements>
      )}
    </div>
  )
}