import React,{FC} from "react"
import logo from 'assets/img/xT-Sales.png'
import styles from './header.module.scss'

export const XtHeader : FC<{}> = () => {
    return (
        <div className={styles.headerContainer}>
            <img className={styles.headerLogo} src={logo} alt="xTuple Logo" />
        </div> 
    )
}
