export const validationMessage = {
  isRequired: 'This field is required.',
  invalidNumber: 'Invalid number.',
  invalidDate: 'Invalid date format.',
  invalidEmail: 'Please enter a valid email address.',
  invalidPhone: 'Please enter a valid phone number.',
  invalidFormat: 'Invalid format.',
  invalidPatternValidator: 'Validator should have valid Regex syntax.',
  invalidInputMask: 'Mask is invalid. Please check the mask definitions.',
  inputMaskHasConflictWithValidator: "Mask shouldn't conflict with the validator.",
  minimumDate: '01/01/1900',
  passwordMatch: 'Passwords do not match.',
  invalidExpiration: 'The expiration date is invalid',
  invalidCvv: 'The CVV code is invalid',
  min: (length: number, scale: number = 0) => `Should be more than ${length.toFixed(scale)}.`,
  max: (length: number, scale: number = 0) => `Should be less than ${length.toFixed(scale)}.`,
  minDate: (min: string) => `Should be later than ${min}.`,
  maxDate: (max: string) => `Should be earlier than ${max}.`,
  uniqueValue: (value: string) => `The value should differ from ${value}`,
  invalidInteger : 'Please enter a valid integer',
  minimumUser : 'You must purchase at least one user'
}

export const validationRegExp: {
  phone: RegExp
  email: RegExp
  fileType: RegExp
  cvv: RegExp
  expirationDate: RegExp
  hostName : RegExp,
  alphanumeric : RegExp,
  decimalNumber : RegExp
} = {
  phone: /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ,
  fileType: /(jpg|jpeg|png|gif|bmp|tiff)$/i,
  cvv: /^[0-9]{3,4}$/,
  expirationDate: /([0-9]{2})\/([0-9]{2})/,
  hostName: /[^\w-]/,
  alphanumeric : /[0-9a-zA-Z]/,
  decimalNumber : /^\d*\.{1}\d*$/
}

export function validatePhone(phone?: string): boolean {
  if (!phone) {
    return false
  }
  return validationRegExp.phone.test(phone)
}

export function validateEmail(email?: string): boolean {
  if (!email) {
    return false
  }
  return validationRegExp.email.test(email)
}

export function validateFileType(type?: string): boolean {
  if (!type) {
    return false
  }
  return validationRegExp.fileType.test(type)
}

export const maxNumber = 10 ** 10
export const minDate = '1900-01-01'
