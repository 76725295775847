import { FormRadioGroupOptions } from 'components/controls/xt-radio-group/xt-radio-group'

export enum IPlanInfoFormField {
  Users = 'users',
  Billing = 'billing',
  Hostname = 'hostname'
}

export enum IPlanInfoFormLabel {
  Users = 'How many users to license',
  Billing = 'Billing',
  Hostname = 'Hostname',
  Amount = 'Amount'
}

export const defaultPlanInfoState = {
  [IPlanInfoFormField.Users]: 5,
  [IPlanInfoFormField.Billing]: 'M',
  [IPlanInfoFormField.Hostname]: '',
}

export enum BillingPlanOptions {
  Monthly = 'M',
  Annually = 'A'
}

export enum BillingPlanOptionsLabels {
  Monthly = 'Monthly',
  Annually = 'Annually'
}

export const planInfoOptions: FormRadioGroupOptions = [
  { label: BillingPlanOptionsLabels.Monthly, id: BillingPlanOptions.Monthly },
  { label: BillingPlanOptionsLabels.Annually, id: BillingPlanOptions.Annually },
]
