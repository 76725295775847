import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Divider } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { ProgressStepper } from './progress-stepper/progress-stepper'
import { IPlanInfoFormState } from './account-configuration-steps/plan-information/plan-information.types'
import { IAccountInfoFormState } from './account-configuration-steps/contact-information/contact-information.types'
import { IUserInfoFormState } from './account-configuration-steps/user-information/user-information.types'
import { defaultWizardDialogState, defaultWizardState, defaultSetupWizardRoute } from './setup-wizard.constants'
import { ISetupWizardDialogState, ISetupWizardRequestParams, ISetupWizardModuleState, ISetupModule, ISetupModuleStep} from './setup-wizard.types'
import { setupModuleList, SetupModulesParams, AccountConfigStepParams, AccountConfigSteps } from './setup-wizard.exports'
import { countSteps, getCurrentStep, setNewFormData, parseFormDataToPost } from './setup-wizard.utils'
import styles from './setup-wizard.module.scss'
import { SetupWizardService } from './setup-wizard.service'
import { getTokenFromURL } from './account-configuration-steps/verify-email/verify-email.utils'
import { ErrorHandler } from 'services/error-handler.service'

export const SetupWizard: FC = () => {
  const history = useHistory()

  const { activeModule, activeStep } = useParams<ISetupWizardRequestParams>()

  const [wizardState, setWizardState] = useState<ISetupWizardDialogState>(defaultWizardDialogState)
  const [formState, setFormState] = useState<ISetupWizardModuleState>(defaultWizardState)
  const [isLoading, setLoading] = useState<boolean>(false)

  const [_, setSkip] = useState<boolean>(false)
  const [errorMessage,setErrorMessage] = useState('')

  const isValid =
    wizardState.isValid ||
    wizardState.activeStep.id === setupModuleList[1].steps[1].id ||
    wizardState.activeStep.id === setupModuleList[1].steps[2].id ||
    (wizardState.activeModule === defaultWizardDialogState.activeModule &&
      wizardState.activeStep.id === defaultWizardDialogState.activeStep.id)

  const onChangeData = useCallback(
    (formData: IUserInfoFormState | IAccountInfoFormState | IPlanInfoFormState, isFormDirty: boolean, isFormValid: boolean) => {
      
      const newFormData = setNewFormData(formState,wizardState,activeModule,formData)

      setWizardState((prevState) => ({
        ...prevState,
        isDirty: isFormDirty,
        isValid: isFormValid,
      }))
      
      setFormState(newFormData)
    },
    [formState, wizardState, activeModule]
  )

  const onSaveData = useCallback(async (): Promise<void> => {
      const custPostData = parseFormDataToPost(formState.account)
      const customerNumber : string= await SetupWizardService.saveCustomer(custPostData)
      
      setFormState( prevFormState => ({
        ...prevFormState,
        account : {
          ...prevFormState.account,
          contactInfo : {
            ...prevFormState.account.contactInfo,
            customerNumber
          }
        }
      }))

      
      }, [activeModule, activeStep])

  const onNextStep = useCallback(async () => {
    try{

      if (isValid) {
        if (wizardState.activeStep.id === wizardState.activeModule.steps.length) {
         
          if (setupModuleList[setupModuleList.length - 2].name === wizardState.activeModule.name) {
            setSkip(true)
          }
          const nextModule = setupModuleList[wizardState.activeModule.id]
          setWizardState((prevState) => ({
            ...prevState,
            isValid: false,
            activeModule: nextModule,
            activeStep: {
              id: nextModule.steps[0].id,
              state: nextModule.steps[0],
            },
          }))
          history.push({
            pathname: `/${nextModule.param}/${nextModule.steps[0].name}`,
          })
          return
        }
        else if(activeModule === SetupModulesParams.AccountConfiguration && activeStep === AccountConfigSteps.UserInfo){
          setLoading(true)
         await SetupWizardService.saveUserInfo({...formState.account.userInfo,verify_password : undefined})
         setLoading(false)
        }

         else if (setupModuleList[setupModuleList.length - 1].name === wizardState.activeModule.name) {
          setLoading(true)
          await onSaveData()
          setLoading(false)
        }
        const nextStep = wizardState.activeStep.id + 1
        setWizardState((prevState) => ({
          ...prevState,
          isValid: false,
          activeStep: {
            id: nextStep,
            state: wizardState.activeModule.steps[nextStep - 1],
          },
        }))
        history.push({
          pathname: `/${wizardState.activeModule.param}/${wizardState.activeModule.steps[nextStep - 1].name}`,
        })
      }
    }
    catch(e){
       setLoading(prevLoading => prevLoading ? false : prevLoading)
       ErrorHandler.handleError(e as Error)
    }
  }, [wizardState, history, onSaveData, isValid])

  const onPreviousStep = useCallback(() => {
    if (wizardState.activeStep.id === defaultWizardDialogState.activeStep.id) {
      if (wizardState.activeModule === defaultWizardDialogState.activeModule) {
        return
      }
    }
    if (formState.final.processPayment.isPaid === true) {
      return
    }
    if (wizardState.activeStep.id === wizardState.activeModule.steps[0].id) {
      const newModule = setupModuleList[wizardState.activeModule.id - 2]
      const [lastStep] = newModule.steps.slice(-1)
      setWizardState((prevState) => ({
        ...prevState,
        isValid: false,
        activeModule: newModule,
        activeStep: {
          id: lastStep.id,
          state: wizardState.activeModule.steps[lastStep.id - 1],
        },
      }))
      history.push({
        pathname: `/${newModule.param}/${lastStep.name}`,
      })
      return
    }
    const filteredStep = wizardState.activeModule.steps.filter((item) => item.id === wizardState.activeStep.id - 1)[0]
    setWizardState((prevState) => ({
      ...prevState,
      isValid: false,
      activeModule: wizardState.activeModule,
      activeStep: {
        id: filteredStep.id,
        state: wizardState.activeModule.steps[filteredStep.id - 1],
      },
    }))
    history.push({
      pathname: `/${wizardState.activeModule.param}/${filteredStep.name}`,
    })
  }, [wizardState, history, formState.final.processPayment.isPaid])

  const getProgress = useCallback(() => {
    const currentStep = getCurrentStep(wizardState)
    if (currentStep === 1) return 0
    return (currentStep / countSteps()) * 100
  }, [wizardState])



  const verifyEmail = useCallback(async () => {
    try {      
        const token = getTokenFromURL()
        const userInfo = await SetupWizardService.verifyEmail(token)
        const userInfoFormState = setNewFormData(formState,wizardState,SetupModulesParams.AccountConfiguration,userInfo)
        setFormState(userInfoFormState)
        await onNextStep()
    } catch (e) {
      console.error(e)
      const verifyEmailError= ErrorHandler.handleEmailVerificationError(e as Error)
      setErrorMessage(verifyEmailError)
    }
  },[onNextStep,wizardState.activeModule.id,wizardState.activeStep.id])


  const renderNextButton = useCallback(()=>(
     (activeModule !== SetupModulesParams.AccountConfiguration || activeStep !== AccountConfigSteps.ConfirmEmail) && <XtButton label="Next" onClick={onNextStep} loading={isLoading} disabled={!isValid} /> 
   ),[isValid,activeModule,activeStep,isLoading])

  useEffect(() => {
    const filteredModule = setupModuleList.filter((item) => item.param === activeModule)[0]
    
    if (filteredModule) {
      const filteredStep = filteredModule.steps.filter((item) => item.name === activeStep)[0]
      const isLastStep =
        setupModuleList[setupModuleList.length - 1].name === filteredModule.name &&
        wizardState.activeStep.id === wizardState.activeModule.steps.length
      setWizardState((prevState) => ({
        ...prevState,
        isLastStep,
        activeModule: filteredModule,
        activeStep: {
          id: filteredStep.id,
          state: filteredStep,
        },
      }))
    }
    // eslint-disable-next-line
  }, [activeStep, activeModule])

  useEffect(() => {    
    if (!activeModule || !activeStep) {
      history.push({
        pathname: defaultSetupWizardRoute,
      })
    }

  //When user clicks the Email Verification Link. Initialize wizardState to Verify Email step
    else if(activeModule === SetupModulesParams.AccountConfiguration && activeStep === AccountConfigStepParams.VerifyEmail)
      {
        const accountModule : ISetupModule = setupModuleList.filter(item => item.param === SetupModulesParams.AccountConfiguration)[0]
        const verifyEmailStep : ISetupModuleStep = accountModule.steps.filter(step => step.name === AccountConfigSteps.VerifyEmail)[0]
        setWizardState( prevState => ({
          ...prevState,
          isValid : false,
          isDirty : false,
          isLastStep : false,
          activeModule : accountModule,
          activeStep : {
            id : verifyEmailStep.id,
            state : verifyEmailStep
          }
        }))   
      }
    // eslint-disable-next-line
  }, [])


  //Call VerifyEmailAPI Accordingly
  useEffect(() => {
    if(wizardState?.activeModule?.param === SetupModulesParams.AccountConfiguration && wizardState?.activeStep?.state?.param === AccountConfigStepParams.VerifyEmail)
    {
      verifyEmail().catch(e => console.error(e))
    }
  },[wizardState])


  return (
    <div className={styles.setupWizardContainer}>
      <div className={styles.setupWizardMain}>

        <div className={styles.setupWizardContent}>
          <ProgressStepper state={wizardState} />
          {setupModuleList.map((setupModule) => {
            return setupModule.param === activeModule
              ? setupModule.steps.map((step, stepIndex) => {
                  return step.name === activeStep ? (
                    <step.component
                      onChange={onChangeData}
                      key={stepIndex}
                      onSubmit={onNextStep}
                      onValidate={wizardState.validate}
                      state={formState}
                      errorMessage={errorMessage}
                    />
                  ) : (
                    ''
                  )
                })
              : ''
          })}
        </div>
      </div>
      <Divider className={styles.sectionDivider} />
      <div className={styles.buttonToolbar}>
        <div className={styles.progressLoader}>
          <CircularProgress variant="determinate" value={getProgress()} />
          <div className={styles.currentProgress}>
            <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(getProgress())}%`}</Typography>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <XtButton
            label="Back"
            onClick={onPreviousStep}
            loading={isLoading}
            disabled={
              (wizardState.activeModule.id === defaultWizardDialogState.activeModule.id &&
                wizardState.activeStep.id === defaultWizardDialogState.activeStep.id) ||
              formState.final.processPayment.isPaid
            }
          />
          {renderNextButton()}
        </div>
      </div>
    </div>
  )
}
