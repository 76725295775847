import React, { FC } from 'react'
import styles from './welcome-msg.module.scss'

export const WelcomeMsg: FC = () => {
  return (
    <div className={styles.welcomeMsgContainer}>
      <p className={styles.welcomeMsgText}>Welcome to xT, your next-generation modular ERP solution, brought to you by xTuple - the leader in manufacturing and distribution ERP for over 20 years!</p>
      <p className={styles.welcomeMsgText}>xT will assist with a fast and easy setup with basic sales and CRM information.  This setup will take only a few minutes. To begin, we will need to collect basic contact information for you and your company.</p>
      <p className={styles.welcomeMsgText}>Click  &quot;Next&quot; to get started...</p>
    </div>
  )
}
