import { PropsWithChildren, ReactElement } from 'react'
import { TableCellProps } from '@material-ui/core/TableCell/TableCell'
import { IObjectWithId } from '../../../common/common.types'

export type TableValueConverter<TValue = unknown> = (value: TValue) => string

export interface IXtTableColumn<TData extends IObjectWithId> extends Pick<TableCellProps, 'align' | 'height'> {
  id: string
  field?: string
  headerName?: string
  renderCell?(item: TData): ReactElement
  width?: number
  flex?: number | string
  sticky?: boolean
  converter?: TableValueConverter
  unsortable?: boolean
}

export enum SortDirection {
  Ascending = 'asc',
  Descending = 'desc',
}

export interface ISortOption {
  sortField: string
  sortDirection?: SortDirection
}

export type TSortOptions = Map<string, ISortOption>

export type TableSortingFn = (sortOption: ISortOption) => Promise<void>

export interface IXtTableHeadProps<TData extends IObjectWithId> {
  columns: IXtTableColumn<TData>[]
  loading: boolean
  sortOptions?: TSortOptions
  onSortLabelClick?: TableSortingFn
}

export type IXtTableHeadType = <TData extends IObjectWithId>(
  props: PropsWithChildren<IXtTableHeadProps<TData>>,
  context?: any // eslint-disable-line @typescript-eslint/no-explicit-any
) => ReactElement | null

export enum TableCell {
  Action = 'Action',
}
