export enum IResendEmailFormField {
   Email = 'email'
  }
  
  export enum IResendEmailFormLabel {
    Email = 'Email'
  }
  
  export const defaultResendEmailState = {
    [IResendEmailFormField.Email]:''
  }
