import React from 'react'
import { CircularProgress } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

interface ILoader {
  text?: string
  className?: string
}

export function LoadingSpinner({ text = 'Loading...', className }: ILoader): JSX.Element {
  return (
    <div className={className}>
      <CircularProgress size={15} /> {text}
    </div>
  )
}

export function LoadingScreenSpinner({ text = 'Loading...', className }: ILoader): JSX.Element {
  return (
    <div className={className}>
      <div style={{textAlign: 'center'}}>
        <CircularProgress />
        <Typography variant="h6">{text}</Typography>
      </div>
    </div>
  )
}