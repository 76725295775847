import breakpoints from 'assets/sass/breakpoints.module.scss'

export interface IGlobalConstants {
  apiUrl: string
  siteUrl: string
  dateFormat: string
  datetimeFormat: string
  serverDateFormat: string,
  formChangeDebounce: number
  paginationLimit: number
  inputDebounce: number
}

export const globalConstants: IGlobalConstants = {
  apiUrl: process.env.REACT_APP_API_URL || 'https://xtdemo.xtuple.com/v0',
  siteUrl: process.env.REACT_APP_SITE_URL || 'http://localhost:3000',
  dateFormat: 'MM/dd/yyyy',
  datetimeFormat: 'MM/dd/yyyy hh:mm aa',
  serverDateFormat: 'yyyy-MM-dd',
  formChangeDebounce: 100,
  paginationLimit: 10,
  inputDebounce: 700,
}

export const xsMq = `(max-width:${breakpoints.xs})`
export const smMq = `(max-width:${breakpoints.sm})`
export const mdMq = `(max-width:${breakpoints.md})`
export const lgMq = `(max-width:${breakpoints.lg})`

export const maskDefinitions = {
  letter: {
    symbol: '#',
    pattern: /\p{L}/gu,
  },
  digit: {
    symbol: '0',
    pattern: /[0-9]/,
  },
  any: {
    symbol: '*',
    pattern: /./,
  },
}

export const inputMaskDefinitions = {
  [maskDefinitions.letter.symbol]: maskDefinitions.letter.pattern,
  [maskDefinitions.digit.symbol]: maskDefinitions.digit.pattern,
  [maskDefinitions.any.symbol]: maskDefinitions.any.pattern,
}

export enum XtTypographyVariants{
  Caption = 'caption'
}

export enum XtInfoMessages{
 RequiredFields  = 'Required fields are marked with an asterisk(*)'
}
