export enum IUserInfoFormField {
  FirstName = 'first_name',
  MiddleName = 'middle_name',
  LastName = 'last_name',
  Email = 'email',
  Phone = 'phone',
  Password = 'password',
  VerifyPassword = 'verify_password',
  Username = 'username'
}

export enum IUserInfoFormLabel {
  FirstName = 'First Name',
  MiddleName = 'Middle Name',
  LastName = 'Last Name',
  Email = 'Email',
  Phone = 'Phone',
  Password = 'Password',
  VerifyPassword = 'Confirm Password'
}

export const defaultUserInfoState = {
  [IUserInfoFormField.FirstName]: '',
  [IUserInfoFormField.MiddleName]: '',
  [IUserInfoFormField.LastName]: '',
  [IUserInfoFormField.Phone]: '',
  [IUserInfoFormField.Password]: '',
  [IUserInfoFormField.VerifyPassword]: '',
  [IUserInfoFormField.Username]:''
}