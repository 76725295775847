import React,{FC} from "react"
import { IErrorMessage } from "./error-page.types"
import styles from "./error-page.module.scss"


export const ErrorPage : FC<IErrorMessage> = ({title , subTitle=''}) => {
    return (
    <div className={styles.container}>
        <div className={styles.fof}>
                <h1>{title}</h1>
                <p>{subTitle}</p>
      </div>
    </div>
    )
}