export enum XtButtonLabels {
    Yes = 'Yes',
    No = 'No',
    OK = 'OK',
    Cancel = 'Cancel',
    Next = 'Next',
    Previuos = 'Previous',
    Back = 'Back',
    PayNow='Pay Now',
    Submit = 'Submit'
  }