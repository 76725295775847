import React, { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { FormField, FormRadioGroup } from 'common/utils/form/form.components'
import { IPlanInformation, IPlanInfoFormState } from './plan-information.types'
import { 
  IPlanInfoFormField,
  IPlanInfoFormLabel,
  planInfoOptions
} from './plan-information.constants'
import { generateDefaultData, calculateCost } from './plan-information.utils'
import styles from './plan-information.module.scss'
import { yupResolver } from '@hookform/resolvers/yup'
import { PlanInformationSchema } from './plan-informaion.validation'
import { FormFieldTypes } from 'common/hooks/form/form.exports'
import { Typography } from '@material-ui/core'
import { XtInfoMessages, XtTypographyVariants } from 'common/constants'
import { IXtInputMasks } from 'components/controls/xt-input/xt-input.constants'

export const PlanInformation: FC<IPlanInformation> = ({ state, onChange }) => {
  const {
    control,
    getValues,
    formState: { isDirty, isValid },
    watch
  } = useForm<IPlanInfoFormState>({
    defaultValues: generateDefaultData(state),
    mode: 'onChange',
    resolver : yupResolver(PlanInformationSchema)
  })

  useEffect(() => {
    const values = getValues()
    onChange(values, isDirty, isValid)
  // eslint-disable-next-line
  }, [isDirty, isValid, getValues])

  useEffect(() => {
    const subscription = watch((value) => {
      onChange(value, isDirty, isValid)
    })
    return () => subscription.unsubscribe()
  // eslint-disable-next-line
  }, [watch, isDirty, isValid])

  return (
    <>
    <form>
      <p className={styles.planFormTitle}>Your private xT Sales database will be managed in our secure xTuple Cloud. We have provided a suggested host name for your xTuple server but you may change it here. Finally, decide if you would like to pay monthly or annually (at a 20% discount).</p>
      <div className={styles.hostnameFieldContainer}>
        <FormField
          control={control}
          name={IPlanInfoFormField.Hostname}
          label={`${IPlanInfoFormLabel.Hostname}*`}
          className={styles.hostnameField}
        />
        <span>.xtuplecloud.com</span>
      </div>

      <FormField
        control={control}
        name={IPlanInfoFormField.Users}
        label={`${IPlanInfoFormLabel.Users}*`}
        type={FormFieldTypes.Number}
        mask={IXtInputMasks.License}
      />
      <p>How do you wish to pay?</p>
      <FormRadioGroup
        options={planInfoOptions}
        label=""
        control={control}
        name={IPlanInfoFormField.Billing}
      />
      <p>Your {state.account.planInfo.billing === 'M' ? 'monthly' : 'annual'} cost will be ${calculateCost(state.account.planInfo.users, state.account.planInfo.billing)}</p>
    </form>
    <Typography variant={XtTypographyVariants.Caption}>
        {XtInfoMessages.RequiredFields}
      </Typography>
    </>
    
  )
}