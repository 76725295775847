import { deepEqual } from 'fast-equals'

function isValue<TData>(value: Record<string, TData> | TData): value is TData {
  return value === null || value === undefined || value instanceof Date || value instanceof Array || typeof value !== 'object'
}

function extractValue<TData>(object: Record<string, TData | Record<string, TData>>, fields: string[], defaultValue?: TData): TData | null {
  const [field, ...otherFields] = fields
  if (!field && !isValue(object)) {
    return defaultValue ?? null
  }
  const value = object[field]
  return isValue(value) ? value : extractValue(value, otherFields, defaultValue)
}

/**
 * Function returns a nested object value
 * @param object - object used to get value from
 * @param path - path that defines nested property location. Should be in format 'x.y.z'
 * @param defaultValue - default value used if nested property is not found
 */
export function resolvePath<TData>(object: object, path: string, defaultValue?: TData): TData | null {
  const fields = path.split('.')

  if (!fields.length || isValue(object)) {
    return defaultValue ?? null
  }
  return extractValue(object, fields, defaultValue)
}

export function isEqual(object1: object, object2: object): boolean {
  return deepEqual(object1, object2)
}

/**
 * Binds all the class instance methods to the instance itself to prevent any issues with JS context.
 * @param instance
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function bindAllInstanceMethods<T extends Record<string, any>>(instance: T): void {
  function isMethodProperty(property: unknown): property is CallableFunction {
    return typeof property === 'function'
  }

  const selfProtoKeys: Array<keyof T> = Object.getOwnPropertyNames(Object.getPrototypeOf(instance)) as Array<keyof T>
  selfProtoKeys.forEach((key) => {
    const property = instance[key]
    if (isMethodProperty(property)) {
      // eslint-disable-next-line no-param-reassign
      instance[key] = property.bind(instance)
    }
  })
}