import { ResendEmail } from 'setup-wizard/resend-email/resend-email'
import { SetupWizard } from 'setup-wizard/setup-wizard'

export const appRoutes = [
  {
    name: 'Setup Wizard Route',
    path: '/:activeModule/:activeStep',
    component: SetupWizard,
    exact: true
  },
  {
    name : 'Resend Verfication Email',
    path : '/resend-email',
    component : ResendEmail,
    exact :  true
  }
]