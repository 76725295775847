import { useCallback, useEffect, useRef, useState } from 'react'
import { ErrorHandler } from 'services/error-handler.service'
import { globalConstants } from 'common/constants'
import {
  IXtAutocompleteOption,
  XtAutocompleteFiltersType,
  XtAutocompleteLoadOptionsFunc,
  XtAutocompleteOptionType,
  XtAutocompleteFilterFunc,
} from './xt-autocomplete.types'

interface IXtAutocompleteMeta<Option extends IXtAutocompleteOption, TFilters> {
  page: number
  total: number
  itemsLimitPerRequest: number
  searchFilter: string | null
  filters: TFilters | undefined
  loadOptionsFn: XtAutocompleteLoadOptionsFunc<Option, TFilters>
}

interface IXtAutocompleteHookState<Option extends IXtAutocompleteOption> {
  loading: boolean
  options: Option[]
}

export interface IXtAutocompleteHook<Option extends IXtAutocompleteOption, TFilters> extends IXtAutocompleteHookState<Option> {
  loadMoreOptions(): Promise<void>
  search(searchFilter: string | null): Promise<Option[]>
  filter: XtAutocompleteFilterFunc<Option, TFilters>
  reset(): Promise<Option[]>
}

export function useXtAutocomplete<
  LoadFunc extends XtAutocompleteLoadOptionsFunc,
  Option extends IXtAutocompleteOption = XtAutocompleteOptionType<LoadFunc>,
  TFilters = XtAutocompleteFiltersType<LoadFunc>
>(
  loadOptions: LoadFunc,
  initialFilters?: TFilters,
  requestOptionsOnStart: boolean = false,
  itemsLimitPerRequest: number = globalConstants.paginationLimit * 2,
  extraOption: Option | null = null
): IXtAutocompleteHook<Option, TFilters> {

  const initOptions = extraOption ? [extraOption] : []

  const metaRef = useRef<IXtAutocompleteMeta<Option, TFilters>>({
    total: 0,
    page: 0,
    itemsLimitPerRequest,
    searchFilter: null,
    loadOptionsFn: (loadOptions as unknown) as XtAutocompleteLoadOptionsFunc<Option, TFilters>,
    filters: initialFilters,
  })

  const [state, setState] = useState<IXtAutocompleteHookState<Option>>({ options: initOptions, loading: false })

  const loadItems = useCallback<(options: Option[], page: number, searchFilter: string | null, filters?: TFilters) => Promise<Option[]>>(
    async (prevOptions, page, searchFilter, filters) => {
      try {
        setState((oldState) => ({ ...oldState, loading: true }))

        const loadOptionsFn = metaRef.current.loadOptionsFn
        const limit = metaRef.current.itemsLimitPerRequest

        const { data, total } = await loadOptionsFn(page, limit, searchFilter, filters as TFilters)

        const options = [...prevOptions, ...data]

        setState({ options, loading: false })
        metaRef.current.page = page
        metaRef.current.total = total
        return options
      } catch (e) {
        setState({ loading: false, options: [] })
        metaRef.current.page = page
        metaRef.current.total = 0
        ErrorHandler.handleError(e as Error)
        return []
      }
    },
    []
  )

  useEffect(() => {
    if (requestOptionsOnStart) {
      const { searchFilter, filters } = metaRef.current
      void loadItems(initOptions, 1, searchFilter, filters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadMoreOptions = useCallback<() => Promise<void>>(async () => {
    const { searchFilter, page, total, itemsLimitPerRequest: limit, filters } = metaRef.current
    if (page * limit < total) {
      await loadItems(state.options, page + 1, searchFilter, filters)
    }
  }, [loadItems, state.options])

  const search = useCallback<(searchFilter: string | null) => Promise<Option[]>>(
    (searchFilter) => {
      metaRef.current.searchFilter = searchFilter
      return loadItems(initOptions, 1, searchFilter, metaRef.current.filters)
    },
    // eslint-disable-next-line
    [loadItems]
  )

  const filter = useCallback<XtAutocompleteFilterFunc<Option, TFilters>>(
    (filters?: TFilters) => {
      metaRef.current.filters = filters
      return loadItems(initOptions, 1, metaRef.current.searchFilter, filters)
    },
    // eslint-disable-next-line
    [loadItems]
  )

  const reset = useCallback<() => Promise<Option[]>>(() => {
    metaRef.current.searchFilter = null
    return loadItems(initOptions, 1, null, metaRef.current.filters)
    // eslint-disable-next-line
  }, [loadItems])

  return {
    ...state,
    filter,
    reset,
    search,
    loadMoreOptions,
  }
}
