import React, { FC } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom"
import { IAppContainer } from './app-container.types'
import styles from  './app-container.module.scss'
import { XtHeader } from 'layouts/header/header'
import { defaultRoute } from './app-container.constants'

export const AppContainer: FC<IAppContainer> = ({ routes }) => {
  
  return (
    <div className={styles.appContainer}>
      <XtHeader/>
      <Router>
        <Switch>
          <Route path="/" exact render={() => <Redirect to={defaultRoute}/>}/>
          {routes.map((route, index) => { 
            return <Route key={index} path={route.path}>{route.component ? <route.component /> : <div />}</Route>
          })}
        </Switch>
      </Router>
    </div>
  )
}