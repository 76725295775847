import React, { useEffect, useState, FC } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { globalConstants } from 'common/constants'
import { IPlanInfoFormLabel } from 'setup-wizard/account-configuration-steps/plan-information/plan-information.constants'
import { ICheckoutForm } from './checkout-form.types'
import styles from './checkout-form.module.scss'
import { XtButtonLabels } from 'components/buttons/xt-button/xt-button.export'
import { configPaymentElementOptions } from './checkout-form.constants'

export const CheckoutForm: FC<ICheckoutForm> = ({ quote, billing, onChange, state }): JSX.Element => {
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret')

    if (!clientSecret) {
      return
    }

    void stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          onChange({ isPaid: true }, true, true)
          setMessage('Payment succeeded!')
          break
        case 'processing':
          setMessage('Your payment is processing.')
          break
        case 'requires_payment_method':
          onChange({ isPaid: false }, false, false)
          setMessage('Your payment was not successful, please try again.')
          break
        default:
          onChange({ isPaid: false }, false, false)
          setMessage('Something went wrong.')
          break
      }
    })
  }, [stripe, onChange])

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${globalConstants.siteUrl}/final/signupComplete?customer_number=${state.account.contactInfo.customerNumber}&users=${state.account.planInfo.users}&billing=${state.account.planInfo.billing}&payment_amount=${quote}&email=${state.account.userInfo.username}`,
      },
    })

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message)
      } else {
        setMessage('An unexpected error occurred.')
      }
    }
    setIsLoading(false)
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={configPaymentElementOptions}/>
      {/* Show any error or success messages */}
      <p>
        By clicking Pay Now, you are agreeing to the{' '}
        <a href="https://www.xtuple.com/products/license/master-services-agreement" className={styles.eulaLink} target="_">
          xTuple Master Services Agreement
        </a>
        ,{' '}
        <a href="https://www.xtuple.com/products/license/eula-dpa#eula" className={styles.eulaLink} target="_">
          End-User License Agreement
        </a>
        , and{' '}
        <a href="https://www.xtuple.com/products/license/eula-dpa#Cloud-Remote-Management" className={styles.eulaLink} target="_">
          Cloud & Remote Services Agreement
        </a>
        , and you authorize xTuple to charge your credit card in the amount of ${quote} on a {billing} basis.
      </p>
      <div className={styles.planDetailsContainer}>
        <p className={styles.planDetail}>
          <strong>{IPlanInfoFormLabel.Hostname}:</strong> {state.account.planInfo.hostname}.xtuplecloud.com
        </p>
        <p className={styles.planDetail}>
          <strong>{IPlanInfoFormLabel.Users}:</strong> {state.account.planInfo.users}
        </p>
        <p className={styles.planDetail}>
          <strong>{IPlanInfoFormLabel.Billing}:</strong> {state.account.planInfo.billing === 'M' ? 'Monthly' : 'Yearly'}
        </p>
        <p className={styles.planDetail}>
          <strong>{IPlanInfoFormLabel.Amount}:</strong> ${quote}
        </p>
      </div>
      {message && !isLoading && (
        <div id="payment-message" className={styles.paymentMessage}>
          <span className="alert-text">{message}</span>
        </div>
      )}
      {state.final.processPayment.isPaid === false && (
        <XtButton disabled={isLoading || !stripe || !elements} type="submit" label={XtButtonLabels.PayNow} />
      )}
    </form>
  )
}
