import { IContactAddress,  ICloudCustomer,  ISetupWizardAccountConfig, ISetupWizardDialogState, ISetupWizardModuleState, IPhone } from './setup-wizard.types'
import { AccountConfigStepParams, IAddressParams, ICloudParams, IPhoneParams, Primary, setupModuleList } from './setup-wizard.exports'
import { IUserInfoFormState } from './account-configuration-steps/user-information/user-information.types'
import { IAccountInfoFormState } from './account-configuration-steps/contact-information/contact-information.types'
import { IPlanInfoFormState } from './account-configuration-steps/plan-information/plan-information.types'
import { getLabelFromAutoCompleteOption } from 'common/utils/utils'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'

export function countSteps(): number {
  let totalSteps = 0
  setupModuleList.forEach((item) => {
    item.steps.forEach(() => {
      totalSteps++
    })
  })
  return totalSteps
}

export function getCurrentStep(state: ISetupWizardDialogState): number {
  let counter = 0
  let currentStep = 0
  setupModuleList.forEach((item) => {
    item.steps.forEach((step) => {
      counter++
      if (item.id === state.activeModule.id && step.id === state.activeStep.id) {
        currentStep = counter
      }
    })
  })
  return currentStep
}


export const setNewFormData = (formState: ISetupWizardModuleState,wizardState : ISetupWizardDialogState,module : keyof ISetupWizardModuleState ,formData : IUserInfoFormState | IAccountInfoFormState | IPlanInfoFormState): ISetupWizardModuleState => {
  const newFormDataKey = wizardState.activeStep.state?.param === AccountConfigStepParams.VerifyEmail ? AccountConfigStepParams.UserInfo : wizardState.activeStep.state?.param
  const newFormData = {
        ...formState,
        [module]: {
          ...formState[module],
          [newFormDataKey]: formData,
        },
      }      
  return newFormData
}

const formFieldsToAddress = (info : IAccountInfoFormState) : IContactAddress => {
  const {address,address2,address3,city,country,state,zipCode,companyName} = info
  return{
    [IAddressParams.Account] : companyName,
    [IAddressParams.Address1] : address,
    [IAddressParams.Address2]:address2,
    [IAddressParams.Address3]: address3,
    [IAddressParams.City] : city,
    [IAddressParams.State] : typeof state === 'object' ? getLabelFromAutoCompleteOption(state as IXtAutocompleteOption) : state,
    [IAddressParams.Country] : typeof country === 'object' ? getLabelFromAutoCompleteOption(country as IXtAutocompleteOption) : country,
    [IAddressParams.PostalCode] : zipCode
  }
}

export const parseFormDataToPost = (account : ISetupWizardAccountConfig) : ICloudCustomer=> {
  const {userInfo,contactInfo,planInfo} : ISetupWizardAccountConfig = account
  const {companyName}: IAccountInfoFormState= contactInfo
  const {first_name,last_name,middle_name,phone,username} : IUserInfoFormState = userInfo
  const {hostname} : IPlanInfoFormState = planInfo
  
 const phones : IPhone[] = [{
  [IPhoneParams.Role] : Primary,
  [IPhoneParams.Number] : phone
 }]
 
  return{
      [ICloudParams.FirstName] : first_name,
      [ICloudParams.LastName] : last_name,
      [ICloudParams.Middlename] : middle_name,
      [ICloudParams.Email] : username,
      [ICloudParams.Phones] : phones,
      [ICloudParams.Company] : companyName,
      [ICloudParams.ContactAddress] : formFieldsToAddress(contactInfo),
      [ICloudParams.HostName] : hostname
  }
}
