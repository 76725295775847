import React,{FC} from 'react'
import { LoadingScreenSpinner } from 'components/loading-spinner'
import { VerifyEmailError, VerifyEmailMessages } from './verify-email.constants'
import { IVerifyEmail } from "./verify-email.types"
import { ErrorPage } from 'components/error-page/error-page'
import { Link } from 'react-router-dom'
import styles from "./verify-email.module.scss"

export const VerifyEmail: FC<IVerifyEmail> = ({errorMessage}) => {
  if(!errorMessage)
  return <p>{<LoadingScreenSpinner text={VerifyEmailMessages.VerifyingEmail} />}</p>
  
  let subTitle = ''
  if(errorMessage) subTitle = VerifyEmailError.InvalidVerificationLink
  return(
    <>
      <ErrorPage title={errorMessage} subTitle={subTitle}/>
      <p className={styles.resendText}>Click <Link to="/resend-email">here</Link> to resend the mail.</p>
    
    </>
  ) 
  }