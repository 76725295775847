import { BehaviorSubject, Observable } from 'rxjs'
import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse } from 'common/common.types'
import { prepareRequestParams } from 'common/utils/request.utils'
import { IHttpClient, HttpClient } from 'core/services/http-client'
import { ICountry, ICountryFilters, ICurrenciesFilters, ICurrency, IDictionaryService, IState, IStateFilters } from './dictionary.types'

export class Dictionary implements IDictionaryService {
  private baseCurrencySubject: BehaviorSubject<ICurrency | null> = new BehaviorSubject<ICurrency | null>(null)
  public baseCurrency$: Observable<ICurrency | null> = this.baseCurrencySubject.asObservable()

  constructor(private readonly apiClient: IHttpClient) {}

  private get baseCurrency(): ICurrency | null {
    return this.baseCurrencySubject.value
  }

  public async getAll(pagination: IPaginationParams, filters?: ICountryFilters): Promise<IPaginationData<ICountry>> {
    const params = prepareRequestParams(pagination, filters)

    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<ICountry>>('/countries', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(countryCode: string): Promise<ICountry> {
    const {
      data: { data },
    } = await this.apiClient.get<IResponse<ICountry>>(`/countries/${countryCode}`)
    if (!Object.keys(data).length) {
      throw new Error(`Country with the code: ${countryCode} is not found.`)
    }
    return data
  }

  public async getStates(countryCode: string, pagination?: IPaginationParams, filters?: IStateFilters): Promise<IPaginationData<IState>> {
    const params = prepareRequestParams(pagination, filters)

    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IState>>(`/states/${countryCode}`, { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getCurrencies(pagination?: IPaginationParams, filters?: ICurrenciesFilters): Promise<IPaginationData<ICurrency>> {
    const params = prepareRequestParams(pagination, filters)

    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<ICurrency>>('/currency', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getBaseCurrency(): Promise<ICurrency | null> {
    if (this.baseCurrency) {
      return this.baseCurrency
    }
    const params = prepareRequestParams({ page: 1, limit: 1 }, { basecurrency: true })

    const {
      data: { data },
    } = await this.apiClient.get<IResponse<ICurrency>>('/currency', { params })

    return Object.keys(data).length ? data : null
  }

  private async initializeBaseCurrency(): Promise<void> {
    try {
      const currency = await this.getBaseCurrency()
      this.baseCurrencySubject.next(currency)
    } catch (e) {
      console.error(e)
    }
  }
}

export const DictionaryService: IDictionaryService = new Dictionary(HttpClient)
