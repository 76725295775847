import { IResponse, IResponseStatus } from 'common/common.types'
import { IHttpClient, HttpClient } from 'core/services/http-client'
import { IUserInfoFormState } from './account-configuration-steps/user-information/user-information.types'
import { IInvoice } from './final-steps/signup-complete/signup-complete.types'
import { ISetupWizardService, IClientSecret, ICustomer } from './setup-wizard.types'

const JWT = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY3MzAwNjExMywianRpIjoiZTY4NmIzOTktNGFlOC00ZWYzLThjMDItMTNhZDhhZGIwZDhiIiwibmJmIjoxNjczMDA2MTEzLCJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiZjZlMmI2YWMtZmZkNi00NWE5LTlmMTgtNzY1N2NlZjBjMjI4IiwiZXhwIjoxNjc1NTk4MTEzLCJ1c2VybmFtZSI6ImFuYXlha0BjYWlzb2Z0LmNvbSJ9.lUyVrOeyMeIxj2_xyvTuu8K4xuqz7Du4DKdziRletu8'

export class SetupWizard implements ISetupWizardService {
  constructor(private readonly apiClient: IHttpClient) {}

  public async getSecret(users: number, billing: string): Promise<IClientSecret> {
    const {
      data: { data },
    } = await this.apiClient.post<IResponse<IClientSecret>>('/df/stripe/cs', { data: { users, billing } }, {headers : {Authorization : JWT} })
    return data
  } 

  public async saveUserInfo(user : IUserInfoFormState): Promise<void>{
    await this.apiClient.post<IResponse<IResponseStatus>>('/df/auth/signup',{data : user})
  }

  public async verifyEmail(token : string): Promise<IUserInfoFormState>{
   const response = await this.apiClient.get<IResponse<IUserInfoFormState>>(`/df/auth/verify-email/${token}`)
   const {data : {data}} = response
   return  data
  }

  public async saveCustomer(customerReq : ICustomer) : Promise<string>{
    const {data : {data : {customer}}} = await this.apiClient.post(`/df/customer/create`,{data : customerReq}, {headers : {Authorization : JWT} })
    return customer
  }

  public async resendEmail(email : string) : Promise<string>{
    const {data :{status : {code}}} = await this.apiClient.post(`/df/auth/resend-email/${email}`)
    return code
  }

  public async createInvoice(invoice : IInvoice): Promise<void> {
    await this.apiClient.post(`/df/customer/payment`,{data: invoice}, {headers : {Authorization : JWT}}) 
  }
}

export const SetupWizardService: ISetupWizardService = new SetupWizard(HttpClient)
