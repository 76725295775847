export enum IAccountInfoFormField {
  CompanyName = 'companyName',
  Address = 'address',
  Address2 = 'address2',
  Address3 = 'address3',
  Country = 'country',
  City = 'city',
  State = 'state',
  ZipCode = 'zipCode',
  CustomerNumber = 'customerNumber'
}

export enum IAccountInfoFormLabel {
  CompanyName = 'Company Name',
  Address = 'Address',
  Address2 = 'Address #2',
  Address3 = 'Address #3',
  Country = 'Country',
  City = 'City',
  State = 'State',
  ZipCode = 'Zip Code',
}

export const defaultContactInfoState = {
  [IAccountInfoFormField.CompanyName]: '',
  [IAccountInfoFormField.Address]: '',
  [IAccountInfoFormField.Address2]: '',
  [IAccountInfoFormField.Address3]: '',
  [IAccountInfoFormField.Country]: null,
  [IAccountInfoFormField.City]: '',
  [IAccountInfoFormField.State]: '',
  [IAccountInfoFormField.ZipCode]: '',
}
