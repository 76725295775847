import React from 'react'

import { Route, BrowserRouter, Switch } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import { StylesProvider } from "@material-ui/core/styles"
import { Toast as ToastProvider } from 'services/toast/toast.provider'
import './assets/sass/exports.scss'

import { AppContainer } from 'layouts/app-container/app-container'

import { appRoutes } from './routes'
import { reportWebVitals } from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StylesProvider injectFirst>
    <ToastProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/" render={() => <AppContainer routes={appRoutes} />} />
        </Switch>
      </BrowserRouter>
      </ToastProvider>
    </StylesProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
