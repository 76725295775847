import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { IConfirmEmail } from './confirm-email.types'

export const ConfirmEmail: FC<IConfirmEmail> = ({ state }) => {
  return (
    <div>
      <p>
        Thank you! We&apos;ve sent a confirmation email to <b>{state.account.userInfo.username}</b> with a link
        to finish the signup process. If you don&#39;t receive the link, please click <Link to="/resend-email">here</Link> to resend.
      </p>
    </div>
  )
}
