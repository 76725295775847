import { validationMessage } from 'common/validation/validation'
import * as Yup from 'yup'
import { IPlanInfoFormField } from './plan-information.constants'

export const PlanInformationSchema = Yup.object().shape({
    [IPlanInfoFormField.Hostname] : Yup.string()
    .required(validationMessage.isRequired),
    [IPlanInfoFormField.Users] : Yup.number()
    .required(validationMessage.isRequired)
    .min(1,validationMessage.minimumUser)
    .typeError(validationMessage.minimumUser)
})