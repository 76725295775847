import React, { FC, useEffect } from 'react'
import { IPlanInfoFormLabel } from 'setup-wizard/account-configuration-steps/plan-information/plan-information.constants'
import { IUserInfoFormLabel } from 'setup-wizard/account-configuration-steps/user-information/user-information.constants'
import { IAccountInfoFormLabel } from 'setup-wizard/account-configuration-steps/contact-information/contact-information.constants'
import { calculateCost } from 'setup-wizard/account-configuration-steps/plan-information/plan-information.utils'
import { IReviewDetails } from './review-details.types'
import styles from './review-details.module.scss'

export const ReviewDetails: FC<IReviewDetails> = ({ state, onChange }) => {
  const contactCountryState = {
    country: typeof(state.account.contactInfo.country) == 'string' ? state.account.contactInfo.country : state.account.contactInfo.country?.label,
    state: typeof(state.account.contactInfo.state) == 'string' ? state.account.contactInfo.state : state.account.contactInfo.state?.label,
  }
  
  useEffect(() => {
    onChange(null, true, true)
    // eslint-disable-next-line
  }, [])

  return (
    <div className={styles.reviewDetailsContainer}>
      <div className={styles.userInformationContainer}>
        <h1 className={styles.sectionHeadline}>User Information</h1>
        <div className={styles.reviewDetail}>
          <p className={styles.reviewDetail}><strong>{IUserInfoFormLabel.FirstName}:</strong> {state.account.userInfo.first_name}</p>
          <p className={styles.reviewDetail}><strong>{IUserInfoFormLabel.MiddleName}:</strong> {state.account.userInfo.middle_name}</p>
          <p className={styles.reviewDetail}><strong>{IUserInfoFormLabel.LastName}:</strong> {state.account.userInfo.last_name}</p>
          <p className={styles.reviewDetail}><strong>{IUserInfoFormLabel.Phone}:</strong> {state.account.userInfo.phone}</p>
          <p className={styles.reviewDetail}><strong>{IUserInfoFormLabel.Email}:</strong> {state.account.userInfo.username}</p>
        </div>
      </div>
      <div className={styles.contactInformationContainer}>
        <h1 className={styles.sectionHeadline}>Contact Information</h1>
        <div className={styles.reviewDetail}>
          <p className={styles.reviewDetail}><strong>{IAccountInfoFormLabel.CompanyName}:</strong> {state.account.contactInfo.companyName}</p>
          <p className={styles.reviewDetail}><strong>{IAccountInfoFormLabel.Address}:</strong> {state.account.contactInfo.address}</p>
          <p className={styles.reviewDetail}><strong>{IAccountInfoFormLabel.Address2}:</strong> {state.account.contactInfo.address2}</p>
          <p className={styles.reviewDetail}><strong>{IAccountInfoFormLabel.Address3}:</strong> {state.account.contactInfo.address3}</p>
          <p className={styles.reviewDetail}><strong>{IAccountInfoFormLabel.Country}:</strong> {contactCountryState.country}</p>
          <p className={styles.reviewDetail}><strong>{IAccountInfoFormLabel.State}:</strong> {contactCountryState.state}</p>
          <p className={styles.reviewDetail}><strong>{IAccountInfoFormLabel.City}:</strong> {state.account.contactInfo.city}</p>
          <p className={styles.reviewDetail}><strong>{IAccountInfoFormLabel.ZipCode}:</strong> {state.account.contactInfo.zipCode}</p>
        </div>
      </div>
      <div className={styles.plannInformationContainer}>
        <h1 className={styles.sectionHeadline}>Plan Information</h1>
        <div className={styles.reviewDetail}>
          <p className={styles.reviewDetail}><strong>{IPlanInfoFormLabel.Hostname}:</strong> {state.account.planInfo.hostname}</p>
          <p className={styles.reviewDetail}><strong>{IPlanInfoFormLabel.Users}:</strong> {state.account.planInfo.users}</p>
          <p className={styles.reviewDetail}><strong>{IPlanInfoFormLabel.Billing}:</strong> {state.account.planInfo.billing === 'M' ? 'Monthly' : 'Yearly'}</p>
          <p className={styles.reviewDetail}><strong>{IPlanInfoFormLabel.Amount}:</strong> ${calculateCost(state.account.planInfo.users, state.account.planInfo.billing)}</p>
        </div>
      </div>
    </div>
  )
}