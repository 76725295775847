import * as React from 'react'
import { FC, useEffect } from 'react'
import { ToastProvider, useToasts } from 'react-toast-notifications'
import { ToastService } from './toast.service'

const toast = {
  autoDismissTimeout: 4000,
  animationTimeout: 500,
  placement: 'bottom-right',
}

export interface IToastRegister {
  children: React.ReactNode
}

export interface IToast {
  children: React.ReactNode
}

const ToastRegister: FC<IToastRegister> = ({ children }) => {
  const { addToast, removeToast, removeAllToasts } = useToasts()
  useEffect(() => ToastService.initialize({ addToast, removeToast, removeAllToasts }), [addToast, removeToast, removeAllToasts])
  return <>{children}</>
}
export const Toast: FC<IToast> = ({ children }) => (
  <ToastProvider
    autoDismiss={toast.animationTimeout}
    transitionDuration={toast.animationTimeout}
    autoDismissTimeout={toast.autoDismissTimeout}
  >
    <ToastRegister>{children}</ToastRegister>
  </ToastProvider>
)
