import React, { useEffect, FC } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { FormField } from 'common/utils/form/form.components'
import { IXtInputMasks } from 'components/controls/xt-input/xt-input.constants'
import { IUserInformation, IUserInfoFormState } from './user-information.types'
import { defaultUserInfoState, IUserInfoFormField, IUserInfoFormLabel } from './user-information.constants'
import { UserFormSchema } from './user-information.validation'
import styles from './user-information.module.scss'
import { Typography } from '@material-ui/core'
import { XtInfoMessages, XtTypographyVariants } from 'common/constants'

export const UserInformation: FC<IUserInformation> = ({ state, onChange }) => {  
  const {
    control,
    reset,
    getValues,
    formState: { isDirty, isValid },
    watch
  } = useForm<IUserInfoFormState>({
    defaultValues: defaultUserInfoState,
    mode: 'onChange',
    resolver: yupResolver(UserFormSchema),
  })

  useEffect(() => {
    const values = getValues()
    onChange(values, isDirty, isValid)
  // eslint-disable-next-line
  }, [isDirty, isValid, getValues])

  useEffect(() => {
    const subscription = watch((value) => {
      onChange(value, isDirty, isValid)
    })
    return () => subscription.unsubscribe()
  // eslint-disable-next-line
  }, [watch, isDirty, isValid])

  useEffect(() => {
    if (state.account.userInfo !== defaultUserInfoState) {
      reset(state.account.userInfo)
    }
  // eslint-disable-next-line
  }, [])

  return (
    <div>
      <p className={styles.entryText}>Please tell us about yourself:</p>
      <form>
        <div className={styles.contactInfoName}>
          <FormField
            control={control}
            name={IUserInfoFormField.FirstName}
            label={`${IUserInfoFormLabel.FirstName}*`}
            className={styles.firstNameField}
          />
          <FormField
            control={control}
            name={IUserInfoFormField.MiddleName}
            label={IUserInfoFormLabel.MiddleName}
            className={styles.middleNameField}
          />
          <FormField
            control={control}
            name={IUserInfoFormField.LastName}
            label={`${IUserInfoFormLabel.LastName}*`}
            className={styles.formField}
          />
        </div>
        <FormField
          control={control}
          name={IUserInfoFormField.Phone}
          label={`${IUserInfoFormLabel.Phone}*`}
          className={styles.formField}
          mask={IXtInputMasks.PhoneMask}
        />
        <FormField
          control={control}
          name={IUserInfoFormField.Username}
          label={`${IUserInfoFormLabel.Email}*`}
          className={styles.formField}
        />
        <FormField
          control={control}
          name={IUserInfoFormField.Password}
          label={`${IUserInfoFormLabel.Password}*`}
          className={styles.formField}
          type="password"
        />
        <FormField
          control={control}
          name={IUserInfoFormField.VerifyPassword}
          label={`${IUserInfoFormLabel.VerifyPassword}*`}
          className={styles.formField}
          type="password"
        />
      </form>
      <Typography variant={XtTypographyVariants.Caption}>
        {XtInfoMessages.RequiredFields}
      </Typography>
    </div>
  )
}