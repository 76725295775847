import { FieldError } from 'react-hook-form'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { TransferListOption } from 'components/controls/xt-transfer-list/xt-transfer-list.types'

export const convertDatePickerValue = (date: unknown): Date | null => {
  return date instanceof Date ? date : null
}

export function shouldShowErrorFunc(isSubmitted: boolean, isTouched: boolean, disabled?: boolean): boolean {
  return !disabled && (isSubmitted || isTouched)
}

export function convertToError(error?: string, fieldError?: FieldError): string | undefined {
  return error !== undefined ? error : fieldError?.message
}

export function convertToAutocompleteValue<Option extends IXtAutocompleteOption>(value: unknown): Option | null {
  if (!value || typeof value !== 'object') {
    return null
  }
  return 'id' in value! && 'label' in value ? (value as Option) : null
}

function isTransferListOption<Option extends TransferListOption>(value: unknown): value is Option {
  if (typeof value !== 'object' || !value) {
    return false
  }
  return 'id' in value && 'label' in value
}

export function convertTransferListValueToOptions<Option extends TransferListOption>(value: unknown): Option[] {
  if (!value || !Array.isArray(value)) {
    return []
  }
  return value.filter<Option>(isTransferListOption)
}

export function convertToTextAreaValue(value: unknown): string | number | ReadonlyArray<string> {
  if (!value) {
    return ''
  }
  if (Array.isArray(value)) {
    return value as ReadonlyArray<string>
  }
  if (typeof value === 'number') {
    return value
  }
  return String(value)
}
